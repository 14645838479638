<template>
  <div>
    <PaymentIndex
      :endpoint="getEndpoint"
      :fstId="`riderCredits_${getId}_`"
      :qso="getEndpointOptions"
    />
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import PaymentIndex from '@/composites/transaction/payment/index/Index.vue'

export default {
  name: 'ViewRiderUserCreditTransactions',

  components: {
    PaymentIndex,
  },

  computed: {
    getId() {
      return this.$route.params.id
    },
    getEndpoint() {
      return (
        useEndpoints.invoice.indexOfPayment() + `user=${this.$route.params.id}&`
      )
    },
    getEndpointOptions() {
      return { append: ``, prepend: `` }
    },
  },
}
</script>
