<template>
  <section>
    <PaymentDetailsModal />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div class="">
            <title-plus :title="pageTitle" :hide-plus="true" />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            title="Total Payment"
            :value="
              `${indexMetaData.summary.currency.symbol} ${indexMetaData.summary.total_credit}`
            "
            variant="purple"
          />
          <SummaryCard
            title="AVG Payment/Day"
            :value="
              `${indexMetaData.summary.currency.symbol} ${indexMetaData.summary.avg_credit_per_day}`
            "
            variant="orange"
          />
        </div>
      </div>
      <template v-if="$acl.canView('transaction')">
        <FSTable
          :qso="qso"
          :fst-id="fstId"
          :headers="getTableHeaders"
          :endpoint="endpoint"
          :currency-enabled="true"
          :currency-attributes="getCurrencyAttributes"
          @meta="(e) => (indexMetaData = e)"
        >
          <template #topLeft="{ slotWidth }">
            <keep-alive>
              <FSTableFilter
                :fst-id="fstId"
                :slot-width="slotWidth"
                :options="getFilteredItems"
              />
            </keep-alive>
          </template>

          <template #default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow :key="itemIndex">
                  <FSTableRowItem>
                    <div
                      @click="popup(item)"
                      class="font-bold text-blue-600 cursor-pointer"
                    >
                      {{ item.id | onlyLastFive }}
                    </div>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <router-link
                      v-if="item.user"
                      :to="{
                        name: 'ViewRiderUserProfile',
                        params: { id: item.user.id },
                      }"
                      class="font-extrabold text-blue-600 capitalize"
                      target="_blank"
                    >
                      {{ item.user.full_name }}
                    </router-link>
                    <span v-else>
                      --
                    </span>
                  </FSTableRowItem>

                  <FSTableRowItem :text="item.date" date />

                  <FSTableRowItem
                    v-if="!item.payment_method_details"
                    :text="item.payment_method"
                  />

                  <FSTableRowItem v-if="item.payment_method_details">
                    <i
                      :class="
                        getPaymentCardIconClass(item.payment_method_details)
                      "
                      v-if="item.payment_method && item.payment_method !== '--'"
                    />
                    <span
                      v-if="item.payment_method && item.payment_method !== '--'"
                      >&nbsp;&nbsp;</span
                    >
                    {{ item.payment_method }}
                  </FSTableRowItem>
                  <FSTableRowItem
                    :text="item.payment_reference_id"
                    :text-fallback="`--`"
                  >
                    <template #default="{ text }">
                      <span v-if="text === '--'">--</span>
                      <span v-else>
                        <span
                          v-if="paymentMethod === 'Stripe'"
                          class="text-blue-500"
                        >
                          <a
                            target="_blank"
                            :href="
                              `https://dashboard.stripe.com/payments/${text}`
                            "
                            >{{ truncate(text, -5, '') }}</a
                          >
                          <span
                            class="cursor-pointer text-gray-500"
                            @click="
                              copyTextToClipboard(
                                `https://dashboard.stripe.com/payments/${text}`
                              )
                            "
                          >
                            <i class="ml-2 far fa-copy"></i>
                          </span>
                        </span>
                        <span v-else>{{ truncate(text, -5, '') }}</span>
                      </span>
                    </template>
                  </FSTableRowItem>

                  <FSTableRowItem :text="getPaymentForText(item.payment_for)" />

                  <FSTableRowItem :text="item" as-currency />

                  <FSTableRowItem>
                    <OtoEyeIcon
                      v-tooltip.bottom="'View Invoice'"
                      @click="popup(item)"
                    />
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <div
                      v-tooltip.bottom="getRefundTooltip(item)"
                      class="refund-action"
                      :class="
                        item.is_refunded
                          ? 'refunded'
                          : item.is_source_refundable
                          ? 'refund'
                          : 'disabled'
                      "
                      @click="showRefundPopup(item)"
                    >
                      {{ item.is_refunded ? 'Refunded' : 'Refund' }}
                    </div>
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  @meta="(e) => (indexMetaData = e)"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <div
                      @click="popup(item)"
                      class="font-bold text-blue-600 capitalize cursor-pointer"
                    >
                      #{{ item.id | onlyLastFive }}
                    </div>
                  </FSTableRowItem>

                  <FSTableRowItem
                    v-if="!item.payment_method_details"
                    :text="item.payment_method"
                  />

                  <FSTableRowItem v-if="item.payment_method_details">
                    <i
                      :class="
                        getPaymentCardIconClass(item.payment_method_details)
                      "
                      v-if="item.payment_method && item.payment_method !== '--'"
                    />
                    <span
                      v-if="item.payment_method && item.payment_method !== '--'"
                      >&nbsp;&nbsp;</span
                    >
                    {{ item.payment_method }}
                  </FSTableRowItem>

                  <FSTableRowItem :text="item" as-currency />
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Payer Name</div>
                      <div class="col-span-5 right-text">
                        <router-link
                          v-if="item.user"
                          :to="{
                            name: 'ViewRiderUserProfile',
                            params: { id: item.user.id },
                          }"
                          class="font-extrabold text-blue-600 capitalize"
                          target="_blank"
                        >
                          {{ item.user.full_name }}
                        </router-link>
                        <span v-else>
                          --
                        </span>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Transaction Time</div>
                      <div class="col-span-5 right-text">
                        {{
                          getFormattedDateTime(
                            item.date,
                            'D MMM, YY hh:mm:ss a'
                          ) || `--`
                        }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Payment Ref</div>
                      <div class="col-span-5 right-text">
                        <span v-if="item.payment_reference_id">
                          <span
                            v-if="paymentMethod === 'Stripe'"
                            class="text-blue-500"
                          >
                            <a
                              target="_blank"
                              :href="
                                `https://dashboard.stripe.com/payments/${item.payment_reference_id}`
                              "
                              >{{
                                truncate(item.payment_reference_id, -5, '')
                              }}</a
                            >
                            <span
                              class="cursor-pointer text-gray-500"
                              @click="
                                copyTextToClipboard(
                                  `https://dashboard.stripe.com/payments/${item.payment_reference_id}`
                                )
                              "
                            >
                              <i class="ml-2 far fa-copy"></i>
                            </span>
                          </span>
                          <span v-else>{{
                            truncate(item.payment_reference_id, -5, '')
                          }}</span>
                        </span>
                        <span v-else>--</span>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Payment for</div>
                      <div class="col-span-5 right-text">
                        {{ getPaymentForText(item.payment_for) || `--` }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Invoice</div>
                      <div class="col-span-5 right-text">
                        <OtoEyeIcon
                          v-tooltip.bottom="'View Invoice'"
                          @click="popup(item)"
                        />
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">Actions</div>
                      <div class="col-span-5 right-text">
                        <div
                          v-tooltip.bottom="getRefundTooltip(item)"
                          class="refund-action"
                          :class="
                            item.is_refunded
                              ? 'refunded'
                              : item.is_source_refundable
                              ? 'refund'
                              : 'disabled'
                          "
                          @click="showRefundPopup(item)"
                        >
                          {{ item.is_refunded ? 'Refunded' : 'Refund' }}
                        </div>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>

      <div v-else class="py-5 font-bold text-center text-gray-600">
        You don't have permission to see data. Please Ask your admin to give you
        permission for this screen.
      </div>
    </content-section>

    <RefundModal @refunded="onRefunded" />
  </section>
</template>

<script>
import { EventBus } from '@/utils/EventBus'
import { TransactionConfig } from '@/config/TransactionConfig'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
  generateCurrencyAttributes,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'
import { getRefundTooltip } from '@/composites/transaction/shared/refund-helpers'
import { getFormattedDateTime } from '@/utils/datetime'
import { truncate } from '@/plugins/truncate'
export default {
  name: 'PaymentIndex',

  props: {
    pageTitle: {
      type: String,
      default: 'Payment',
    },
    fstId: {
      type: String,
      default: 'payments',
    },
    endpoint: {
      type: String,
      default: useEndpoints.invoice.indexOfPayment(),
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '' }),
    },
    filterItems: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    OtoEyeIcon: () => import('@/components/ui/OtoEyeIcon'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    // CardTaxStat: () => import('@/components/cards/TaxStat'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    PaymentDetailsModal: () =>
      import('@/views/transactions/ViewTransactionPopup'),
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    RefundModal: () =>
      import('@/composites/transaction/revenue/index/RefundPopup.vue'),
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      symbol: '$',
      indexMetaData: {
        summary: {
          currency: {
            symbol: '',
          },
          total_credit: '0.00',
          avg_credit_per_day: '0.00',
          total_debit: '0.00',
          avg_debit_per_day: '0.00',
        },
        count: {
          total: 0,
        },
      },
      // indexDataEndpoint: TransactionConfig.api.index,

      tableHeaders: [
        { text: 'Invoice ID', width: '10%', sort: 'id' },
        { text: 'Payer Name', width: '14%', sort: 'user' },
        { text: 'Transaction Time', width: '15%', sort: 'date' },
        { text: 'Payment Method', width: '15%', sort: 'payment' },
        { text: 'Payment Ref', width: '10%', sort: null },
        { text: 'Payment for', width: '17%', sort: 'payment_for' },
        { text: 'Amount', width: '7%', sort: 'amount' },
        { text: 'Invoice', width: '5%', sort: null },
        { text: 'Actions', width: '8%', sort: null },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        { text: 'Invoice ID', width: '25%', sort: 'id' },
        { text: 'Payment Method', width: '35%', sort: 'payment' },
        { text: 'Amount', width: '30%', sort: 'amount' },
      ],
      opened: [],
      // filterable: {},
      filterOptions: [],
      paymentMethod: '',
    }
  },

  async created() {
    this.symbol = this.$org?.default_currency?.symbol || '$'
    await this.generateFilterOptions()
    const allPaymentGateways = await this.$http
      .get(useEndpoints.dropdown.paymentGateways())
      .then((res) => res.data.data)
      .catch((err) => console.log('paymentGatewayErr', err))
    const selectedPaymentGateway = allPaymentGateways.find(
      (pgItem) =>
        pgItem.id ===
        JSON.parse(localStorage.getItem('organization'))
          .default_payment_gateway_config.payment_gateway
    )
    this.paymentMethod = selectedPaymentGateway.name
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getCurrencyAttributes() {
      return generateCurrencyAttributes({
        root: '',
        // def: {
        //   amount: 'settled_amount',
        //   currencyName: 'settled_currency.name',
        //   currencySymbol: 'settled_currency.symbol',
        // },
        def: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
        actual: {
          amount: 'amount',
          currencyName: 'currency.name',
          currencySymbol: 'currency.symbol',
        },
      })
    },
    getFilteredItems() {
      if (this.filterItems.length) {
        if (this.filterItems.length === this.filterOptions.length) {
          let propFilterItems = this.filterItems
          propFilterItems.push({}) //-for updating filteritems into rendering moment
          return propFilterItems
        } else {
          return this.filterItems
        }
      } else {
        return this.filterOptions
      }
    },
  },
  methods: {
    onApplyFilterDateRange,
    getRefundTooltip,
    getFormattedDateTime,
    truncate,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    copyTextToClipboard(link) {
      const el = document.createElement('textarea')
      el.value = link
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-99999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$notify(
        {
          group: 'generic',
          type: 'success',
          title: 'Payment Reference link Copied',
          text: 'The Payment Reference link has been copied to your clipboard',
        },
        2000
      )
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    onFilter(id, payload) {
      this.$store.commit('fsTable/addQsa', { id, payload })
    },
    // todo: accept string & array
    onFilterClear(id) {
      this.$store.commit('fsTable/clearQsa', { id })
    },
    popup(item) {
      EventBus.$emit(TransactionConfig.events.viewingData, item)
      this.$modal.show(TransactionConfig.events.viewingData)
    },
    printAmount(type, amount) {
      let color = type === 'Card' ? 'text-oGreen' : ''
      return `<span class="${color}">${this.symbol} ${
        amount ? parseFloat(amount).toFixed(2) : '0.00'
      }</span>`
    },
    async generateFilterOptions() {
      let filters = [
        {
          key: 'payment_type',
          type: 'checkbox',
          input: [
            { text: 'Card Topup', value: 'Topup' },
            { text: 'Agent Topup', value: 'Agent Topup' },
            { text: 'Signup Bonus', value: 'Initial Balance' },
            {
              text: 'Referral Bonus by Invitee',
              value: 'Invitee Invitation Amount',
            },
            {
              text: 'Referral Bonus by Inviter',
              value: 'Inviter Invitation Amount',
            },
            {
              text: 'Balance Transfer by Admin',
              value: 'Rider Balance Adjust',
            },
          ],

          title: 'Payment Type',
        },
        {
          key: 'refund_status',
          type: 'checkbox',
          input: [
            { text: 'Refunded', value: 'refunded' },
            // { text: 'Not Refunded', value: 'not-refunded' },
          ],

          title: 'Refund Status',
        },
      ]
      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },
    getPaymentCardIconClass(card) {
      const brand = card?.brand?.toLowerCase() || ''
      // get font awesome icon by card type
      let iconClass = 'fab '
      switch (brand?.toLowerCase()) {
        case 'visa':
          iconClass += 'fa-cc-visa'
          break
        case 'mastercard':
          iconClass += 'fa-cc-mastercard'
          break
        case 'americanexpress':
          iconClass += 'fa-cc-amex'
          break
        case 'discover':
          iconClass += 'fa-cc-discover'
          break
        case 'jcb':
          iconClass += 'fa-cc-jcb'
          break
        case 'dinersclub':
          iconClass += 'fa-cc-diners-club'
          break
        default:
          iconClass = 'fas fa-wallet'
          break
      }
      return iconClass
    },
    getPaymentForText(textData) {
      if (textData.isArray) {
        return textData
      } else {
        const filterMap = [
          { text: 'Card Topup', value: 'Topup' },
          { text: 'Agent Topup', value: 'Agent Topup' },
          { text: 'Signup Bonus', value: 'Initial Balance' },
          {
            text: 'Referral Bonus by Invitee',
            value: 'Invitee Invitation Amount',
          },
          {
            text: 'Referral Bonus by Inviter',
            value: 'Inviter Invitation Amount',
          },
          {
            text: 'Balance Transfer by Admin',
            value: 'Rider Balance Adjust',
          },
        ]
        const selectedFilter = filterMap.filter(
          (item) => item.value === textData
        )
        if (selectedFilter.length > 0) {
          return selectedFilter[0].text
        } else {
          return textData
        }
      }
    },

    showRefundPopup(item) {
      if (this.$acl.canNotUpdate('transaction')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'transaction',
          })
        )
        return
      }

      if (item.is_refunded || !item.is_source_refundable) {
        return
      }
      EventBus.$emit(TransactionConfig.events.refundPopup, item)
      this.$modal.show(TransactionConfig.events.refundPopup)
    },
    async onRefunded() {
      await this.$store.dispatch('fsTable/fetchData')
      this.$notify(
        {
          group: 'generic',
          type: 'success',
          title: 'Refunded',
          text: 'Successfully refunded to the rider.',
        },
        3000
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
.refund-action {
  color: #ffffff;
  width: 87px;
  height: 25px;
  padding: 2px;
  font-weight: 400;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;

  &.refund {
    background-color: #ff5500;
  }

  &.refunded {
    color: #5f72bf;
    background-color: #d7ebff;
  }

  &.disabled {
    background-color: #bebebe;
    color: #ffffff;
  }
}
</style>
